import {
    ApolloClient,
    createHttpLink,
    InMemoryCache,
    split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import TimeoutLink from 'apollo-link-timeout';
import { createClient } from 'graphql-ws';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { v4 as uuidv4 } from 'uuid';
import { store } from '../app/store';
import { getTemporaryToken, refreshAccessToken } from './axios';
import { SecureStorageKeys } from './enums';
import router from './router';
import { logout, saveData, saveNetworkError } from './webview/messages';

export async function generateHeaders(includeLocation = false): Promise<{
    [key: string]: string | null | undefined;
}> {
    const storeState = store.getState();
    const headers: {
        [key: string]: string | null | undefined;
    } = {
        'x-app-version': `MA ${storeState.app.version}`,
        'x-event-id': uuidv4(),
        'x-device-type': storeState.app.deviceType,
        'x-base-os': storeState.app.platformOs,
        'x-net-info': storeState.app.netInfo,
        'x-phone-carrier': storeState.app.carrier,
        'x-device-invoked-at': new Date().toISOString(),
        'x-device-guid': storeState.app.deviceGuid,
        'x-webview-version': process.env.REACT_APP_GIT_COMMIT,
    };
    return Promise.race([
        new Promise<{
            [key: string]: string | null | undefined;
        }>((resolve) => {
            if (includeLocation) {
                headers['x-geolocation'] = JSON.stringify({
                    latitude: store.getState().app.latitude,
                    longitude: store.getState().app.longitude,
                });
            }
            resolve(headers);
        }),
        new Promise<{
            [key: string]: string | null | undefined;
        }>((resolve) => setTimeout(() => resolve(headers), 1000)),
    ]);
}

const retryLink = new RetryLink({
    attempts: {
        max: Infinity,
        retryIf: (error, operation) =>
            !!error &&
            ['GetUserCurrentTransaction'].includes(operation.operationName),
    },
    delay: {
        max: 2000,
        jitter: true,
    },
});

const timeoutLink = new TimeoutLink(10000); // 10 seconds timeout

const errorLink = onError(({ networkError, operation }) => {
    if (networkError) {
        // alert(operation.variables.);
        if (
            operation.operationName === 'RemoteStart' ||
            operation.operationName === 'Stop'
        ) {
            const desiredChargieId = operation.variables.qrCode;
            const now = new Date();
            saveNetworkError(
                operation.operationName,
                desiredChargieId,
                now.toString()
            );
        }
        console.log(`[Network error]: ${networkError}`);
    }
});

const httpLink = createHttpLink({
    uri: process.env.REACT_APP_API_ENDPOINT,
});

const logoutLink = onError(({ graphQLErrors }) => {
    if (
        graphQLErrors?.find(
            (error) => error.extensions?.code === 'UNAUTHENTICATED'
        )
    ) {
        router.navigate('/login', { replace: true });
    }
});

const authLink = setContext(async (operation, { headers }) => {
    let token = store.getState().app.accessToken;
    try {
        if (token) {
            const decoded: JwtPayload = jwtDecode<JwtPayload>(token);
            const refreshToken = store.getState().app.refreshToken;
            if (
                decoded &&
                decoded.exp &&
                new Date(decoded.exp * 1000) < new Date()
            ) {
                // Expired JWT, refresh here
                try {
                    let result;
                    // If no user or no refresh token, get a new anonymous token
                    if (
                        decoded.sub ===
                            '00000000-0000-0000-0000-000000000000' ||
                        !refreshToken
                    ) {
                        result = await getTemporaryToken();
                    } else {
                        result = await refreshAccessToken(
                            token,
                            refreshToken,
                            await generateHeaders()
                        );
                        saveData(
                            SecureStorageKeys.ACCESS_TOKEN,
                            result.data.access_token
                        );
                    }
                    token = result.data.access_token;
                } catch {
                    // Refresh token expired
                    logout();
                    router.navigate('/login', { replace: true });
                }
            }
        } else {
            const result = await getTemporaryToken();
            token = result.data.access_token;
        }
    } finally {
        const includeLocation = [
            'GuestRemoteStart',
            'RemoteStart',
            'RemoteStop',
            'RegisterUser',
            'GetProperty',
        ];
        const metadataHeaders: {
            [key: string]: string | null | undefined;
        } = await generateHeaders(
            !!operation.operationName &&
                includeLocation.includes(operation.operationName)
        );
        return {
            headers: {
                ...metadataHeaders,
                ...headers,
                authorization: token ? `Bearer ${token}` : '',
            },
        };
    }
});

const wsLink = new GraphQLWsLink(
    createClient({
        url: `ws${
            process.env.REACT_APP_API_ENDPOINT?.includes('https://') ? 's' : ''
        }://${process.env.REACT_APP_API_ENDPOINT?.replace(
            /https?:\/\//,
            ''
        )}}/`,
        connectionParams: () => {
            let token = store.getState().app.refreshToken;
            return {
                Authorization: token ? `Bearer ${token}` : '',
            };
        },
    })
);

const splitLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query);
        return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
        );
    },
    wsLink,
    httpLink
);

const client = new ApolloClient({
    // different Queries hit the same 'station' field (GetStation, GetStationLogs, GetStationPricingHistory,...)
    // those clash and prevent Apollo from caching the response, this solves that issue
    // https://www.apollographql.com/docs/react/caching/advanced-topics/
    // PS also added the same for 'user'
    cache: new InMemoryCache({
        typePolicies: {
            Query: {
                fields: {
                    station: {
                        read(_, { args, toReference }) {
                            return toReference({
                                __typename: 'Station',
                                id: args?.filter.id,
                            });
                        },
                    },
                    user: {
                        read(_, { args, toReference }) {
                            return toReference({
                                __typename: 'User',
                                id: args?.filter.id,
                            });
                        },
                    },
                },
            },
        },
    }),
    link: authLink
        .concat(retryLink)
        .concat(logoutLink)
        .concat(errorLink)
        .concat(timeoutLink)
        .concat(logoutLink)
        .concat(splitLink),
    defaultOptions: {
        query: {
            fetchPolicy: 'network-only',
        },
    },
});

export default client;
