import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
    RequestedChargieId,
    selectDesiredChargieId,
    selectModalBackground,
    selectPlatformOs,
    selectPlatformPayExists,
    selectRequestedChargieId,
    setActiveChargieId,
    setDesiredChargieId,
    setErrorType,
    setRequestedChargieId,
} from '../../appSlice';
import { ReactComponent as Tooltip } from '../../assets/icons/tooltip.svg';
import { colors } from '../../global/variables';
import {
    selectContactInformation,
    selectPaymentMethod,
    setPaymentMethod,
} from '../../guestSlice';
import { extractStationDetails } from '../../models/Station';
import {
    ChargeStatus,
    Guest,
    RemoteErrorTypes,
    SecureStorageKeys,
} from '../../utils/enums';
import { getTouParsed } from '../../utils/tou';
import { numberToDollarFormat } from '../../utils/utility';
import {
    creditCardCheckout,
    hidePaymentModal,
    logToNative,
    platformPayCheckout,
    saveData,
} from '../../utils/webview/messages';
import PrimaryButton from '../PrimaryButton';
import RadioButtons, { RadioOptions } from '../RadioButtons';
import ErrorModal from '../ErrorModal';

const guestRemoteStartMutation = loader('./guestRemoteStart.graphql');
const getStationPricing = loader('../../gql/getStationPricing.graphql');

const GuestPayment: React.FC = () => {
    const dispatch = useAppDispatch();
    const platformPayExists = useAppSelector(selectPlatformPayExists);
    const platformOs = useAppSelector(selectPlatformOs);
    const desiredChargieId = useAppSelector(selectDesiredChargieId);
    const navigate = useNavigate();
    const paymentMethod = useAppSelector(selectPaymentMethod);
    const modalBackground = useAppSelector(selectModalBackground);
    const contactInformation = useAppSelector(selectContactInformation);
    const [currentPrice, setCurrentPrice] = useState('Fetching');
    const [showBubble, setShowBubble] = useState(false);
    const requestedChargieId = useAppSelector(selectRequestedChargieId);
    const [platformError, setPlatformError] = useState<string | undefined>(
        undefined
    );
    const [ccError, setCCError] = useState<string | undefined>(undefined);
    const [selectedPaymentMethod, setSelectedPaymentMethod] =
        useState<string>('');
    const [evError, setEvError] = useState(false);

    const paymentOptions: RadioOptions[] = [
        {
            label: 'Credit card',
            value: Guest.CREDIT_CARD,
            callback: () => {
                dispatch(setPaymentMethod(null));
                creditCardCheckout();
            },
            errorMessage: ccError,
        },
    ];
    if (platformPayExists) {
        paymentOptions.unshift({
            label: platformOs === 'ios' ? 'Apple Pay' : 'Google Pay',
            value: Guest.PLATFORM_PAY,
            callback: () => {
                dispatch(setPaymentMethod(null));
                platformPayCheckout();
            },
            errorMessage: platformError,
        });
    }

    const handlePaymentChange = (value: string) => {
        setSelectedPaymentMethod(value);
    };

    const [guestRemoteStart, { loading: guestRemoteStartLoading }] =
        useMutation(guestRemoteStartMutation);

    const [
        getStationPricingDetails,
        { error: getStationPricingError, data: getStationPricingdata },
    ] = useLazyQuery(getStationPricing, {
        fetchPolicy: 'no-cache',
    });

    const handleSubmit = async () => {
        try {
            if (paymentMethod && desiredChargieId) {
                startCharge(desiredChargieId);
            } else {
                const message = 'Payment failed. Please try again.';
                if (selectedPaymentMethod === Guest.PLATFORM_PAY) {
                    setCCError(undefined);
                    setPlatformError(message);
                } else {
                    setCCError(message);
                    setPlatformError(undefined);
                }
            }
        } catch (e) {
            // alert(e);
        }
    };

    const startCharge = async (desiredId: RequestedChargieId) => {
        try {
            if (paymentMethod) {
                const status = await getStationPricingDetails({
                    variables: {
                        qrCode: desiredChargieId?.chargieId,
                    },
                });
                if (status && status.data.getStationPricing.station) {
                    const stationData = status.data.getStationPricing.station;
                    if (
                        stationData.lastCommunication &&
                        stationData.ocppStatus
                    ) {
                        const now = new Date();
                        const lastMessage = new Date(
                            stationData.lastCommunication
                        );
                        const difference = Math.abs(
                            now.getTime() - lastMessage.getTime()
                        );
                        if (
                            stationData.ocppStatus.toUpperCase() ===
                                ChargeStatus.AVAILABLE ||
                            difference > 180000
                        ) {
                            setEvError(true);
                            return;
                        }
                    }
                }
                dispatch(setRequestedChargieId(desiredId));
            }
        } catch (e) {
            dispatch(setDesiredChargieId(null));
            navigate('/', { replace: true });
        }
    };

    const goBack = () => {
        navigate('/guest/contact');
    };

    useEffect(() => {
        if (paymentMethod) {
            hidePaymentModal();
            setPlatformError(undefined);
            setCCError(undefined);
        } else {
            setSelectedPaymentMethod('');
        }
    }, [paymentMethod]);

    useEffect(() => {
        const fetchPricingDetails = async () => {
            await getStationPricingDetails({
                variables: {
                    qrCode: desiredChargieId?.chargieId,
                },
            });
        };
        fetchPricingDetails();
    }, []);

    const closeError = () => {
        setEvError(false);
    };

    useEffect(() => {
        if (getStationPricingdata) {
            const startData = extractStationDetails(getStationPricingdata);
            const pricing =
                startData.station.pricing ||
                startData.station.inheritedPricingFrom;
            if (pricing.policy.toLowerCase() === 'tou') {
                if (pricing.jsonData) {
                    const touPrices = getTouParsed(
                        startData.todayTouSeason,
                        pricing.jsonData
                    );
                    const currentPrice = touPrices.filter(
                        (item) => item.active
                    )[0];
                    setCurrentPrice(`${currentPrice.price} per kWh`);
                }
            } else if (pricing.policy === 'HOUR')
                setCurrentPrice(
                    `${numberToDollarFormat(pricing.rate || 0)} per hour`
                );
            else
                setCurrentPrice(
                    `${numberToDollarFormat(pricing.rate || 0)} per kWh`
                );
        } else if (getStationPricingError) {
            logToNative('ERROR with getting pricing in payment page');
        }
    }, [getStationPricingError, getStationPricingdata]);

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        const clickedElement = event.target as HTMLElement;
        if (
            clickedElement.className !== 'bubble' &&
            clickedElement.className !== 'bubble-click'
        ) {
            setShowBubble(false);
        }
    };

    return (
        <div className="guest-pricing-content" onClick={handleClick}>
            <div className="pricing-details-main-content">
                <div className="pricing__current-price">
                    <p
                        className="pricing__price-title"
                        id="guest_payment__title"
                    >
                        Payment
                    </p>
                </div>
                <div
                    className="purchase-auth-message"
                    id="guest_payment_purchase_summary__rates"
                >
                    <div id="guest_payment_payment_auth_message">
                        A temporary hold of $60 or $85 will be used to validate
                        your payment card. The hold will be removed once your
                        session is complete.
                    </div>
                </div>
                <RadioButtons
                    options={paymentOptions}
                    selectedValue={paymentMethod ? selectedPaymentMethod : ''}
                    onChange={handlePaymentChange}
                />
                <div
                    className="purchase-summary"
                    id="guest_payment_purchase_summary"
                >
                    <p
                        className="purchase-summary-title"
                        id="guest_payment_purchase_summary__title"
                    >
                        PURCHASE SUMMARY
                    </p>
                    <div
                        className="purchase-station"
                        id="guest_payment_purchase_summary__station"
                    >
                        Station {desiredChargieId?.chargieId}
                    </div>
                    <div
                        className="purchase-summary-rates"
                        id="guest_payment_purchase_summary__rates"
                    >
                        <div id="guest_payment_purchase_summary__rates_title">
                            Current rate
                        </div>
                        <div id="guest_payment_purchase_summary__rates_price">
                            {currentPrice}
                        </div>
                    </div>
                    {!currentPrice.includes('0.00 per') && (
                        <div className="purchase-summary-rates">
                            <div id="guest_payment_purchase_summary__disclaimer">
                                Minimum purchase $0.50
                            </div>
                        </div>
                    )}
                    <div
                        className="bubble-click"
                        id="guest_payment_help_text"
                        onClick={() => {
                            setShowBubble(true);
                        }}
                    >
                        Processing fee
                        <div
                            className="bubble-wrapper"
                            id="guest_payment_bubble_wrapper"
                        >
                            <div>
                                <Tooltip
                                    className="bubble-icon"
                                    fill={colors.activeWhite}
                                    stroke={colors.activeWhite}
                                    id="guest_payment_tooltip"
                                />
                            </div>
                            {showBubble && (
                                <div
                                    className="bubble bubble-center"
                                    id="guest_payment_bubble"
                                >
                                    Processing fees are 35¢ + 3.5% of the
                                    transaction total
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {modalBackground && (
                <div className="modal">
                    <div
                        id="guest_payment__modal_background"
                        className="modal__background"
                        onClick={hidePaymentModal}
                    />
                </div>
            )}

            <div className="modal__button">
                <PrimaryButton
                    title={'Continue'}
                    onPress={handleSubmit}
                    loading={guestRemoteStartLoading}
                    disabled={guestRemoteStartLoading}
                    id={'guest_payment_modal_button'}
                />
                <button
                    className="payment__back-button"
                    onClick={() => goBack()}
                    id={'guest_payment_back_button'}
                >
                    Back
                </button>
            </div>
            <ErrorModal
                isOpen={evError}
                onClose={closeError}
                errorType={RemoteErrorTypes.EV_NOT_DETECTED}
            />
        </div>
    );
};

export default GuestPayment;
