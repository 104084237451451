import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
    selectDesiredChargieId,
    selectModalBackground,
    selectPlatformOs,
    selectPlatformPayExists,
    selectRequestedChargieId,
    setActiveChargieId,
    setDesiredChargieId,
    setErrorType,
    setHasPaymentMethod,
    setShowNavigator,
} from '../../appSlice';
import { ReactComponent as CreditCard } from '../../assets/CreditCard.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/CloseIcon.svg';
import { RadioOptions } from '../../components/RadioButtons';
import {
    creditCardCheckout,
    hidePaymentModal,
    logToNative,
    platformPayCheckout,
    saveData,
} from '../../utils/webview/messages';

import { useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { store } from '../../app/store';
import { ReactComponent as Tooltip } from '../../assets/icons/tooltip.svg';
import PrimaryButton from '../../components/PrimaryButton';
import RadioButtons from '../../components/RadioButtons';
import { colors } from '../../global/variables';
import { selectPaymentMethod, setPaymentMethod } from '../../guestSlice';
import { extractStationDetails } from '../../models/Station';
import { Guest, SecureStorageKeys } from '../../utils/enums';
import { getTouParsed } from '../../utils/tou';
import { numberToDollarFormat } from '../../utils/utility';

const getStationPricing = loader('../../gql/getStationPricing.graphql');
const remoteStartMutation = loader('../../gql/remoteStart.graphql');
const addPaymentMutation = loader('./addPaymentMethod.gql');
const getUserReloadQuery = loader('./getUserReload.gql');

const App: React.FC = () => {
    const dispatch = useAppDispatch();
    const desiredChargieId = useAppSelector(selectDesiredChargieId);
    const modalBackground = useAppSelector(selectModalBackground);
    const platformPayExists = useAppSelector(selectPlatformPayExists);
    const platformOs = useAppSelector(selectPlatformOs);
    const location = useLocation();
    const [step, setStep] = useState(0);
    const [buttonText, setButtonText] = useState('Add');
    const navigate = useNavigate();
    const [currentPrice, setCurrentPrice] = useState('Fetching');
    const [autoReload, setAutoReload] = useState(true);
    const [showBubble, setShowBubble] = useState(false);
    const [platformError, setPlatformError] = useState<string | undefined>(
        undefined
    );
    const [ccError, setCCError] = useState<string | undefined>(undefined);
    const paymentMethod = useAppSelector(selectPaymentMethod);
    const requestedChargieId = useAppSelector(selectRequestedChargieId);

    const [
        remoteStart,
        {
            data: remoteStartData,
            error: remoteStartError,
            loading: remoteStartLoading,
        },
    ] = useMutation(remoteStartMutation);
    const [
        addPayment,
        {
            data: addPaymentData,
            error: addPaymentError,
            loading: addPaymentLoading,
        },
    ] = useMutation(addPaymentMutation);
    const { data: userReloadData } = useQuery(getUserReloadQuery, {
        fetchPolicy: 'no-cache',
    });

    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string>(
        Guest.PLATFORM_PAY
    );
    const paymentOptions: RadioOptions[] = [
        {
            label: 'Credit card',
            value: Guest.CREDIT_CARD,
            callback: () => {
                dispatch(setPaymentMethod(null));
                creditCardCheckout();
            },
            errorMessage: ccError,
        },
    ];
    if (platformPayExists) {
        paymentOptions.unshift({
            label: platformOs === 'ios' ? 'Apple Pay' : 'Google Pay',
            value: Guest.PLATFORM_PAY,
            callback: () => {
                dispatch(setPaymentMethod(null));
                platformPayCheckout();
            },
            errorMessage: platformError,
        });
    }
    const handlePaymentChange = (value: string) => {
        setSelectedPaymentMethod(value);
    };

    const { error: getStationPricingError, data: getStationPricingdata } =
        useQuery(getStationPricing, {
            variables: { qrCode: desiredChargieId?.chargieId },
            skip: !desiredChargieId,
        });

    useEffect(() => {
        dispatch(setShowNavigator(false));
    });

    useEffect(() => {
        if (getStationPricingdata) {
            const startData = extractStationDetails(getStationPricingdata);
            const pricing =
                startData.station.pricing ||
                startData.station.inheritedPricingFrom;
            if (pricing.policy.toLowerCase() === 'tou') {
                if (pricing.jsonData) {
                    const touPrices = getTouParsed(
                        startData.todayTouSeason,
                        pricing.jsonData
                    );
                    const currentPrice = touPrices.filter(
                        (item) => item.active
                    )[0];
                    setCurrentPrice(`${currentPrice.price} per kWh`);
                }
            } else if (pricing.policy === 'HOUR')
                setCurrentPrice(
                    `${numberToDollarFormat(pricing.rate || 0)} per hour`
                );
            else
                setCurrentPrice(
                    `${numberToDollarFormat(pricing.rate || 0)} per kWh`
                );
        } else if (getStationPricingError) {
            logToNative('ERROR with getting pricing in payment page');
        }
    }, [getStationPricingError, getStationPricingdata]);

    useEffect(() => {
        setAutoReload(!!userReloadData?.loggedInUser?.reload);
    }, [userReloadData]);

    useEffect(() => {
        if (paymentMethod) {
            hidePaymentModal();
            setPlatformError(undefined);
            setCCError(undefined);
        } else {
            setSelectedPaymentMethod('');
        }
    }, [paymentMethod]);

    const nextStep = async () => {
        if (step === 0) {
            setStep(step + 1);
            setButtonText('Continue');
        } else if (step === 1) {
            if (desiredChargieId) {
                try {
                    if (paymentMethod) {
                        const paymentRes = await addPayment({
                            variables: {
                                paymentMethodId: paymentMethod,
                            },
                        });
                        if (paymentRes.data.addPaymentMethod) {
                            store.dispatch(setHasPaymentMethod(true));
                            const start = await remoteStart({
                                variables: {
                                    qrCode: desiredChargieId.chargieId,
                                    method: requestedChargieId?.startType,
                                },
                            });
                            if (start) {
                                saveData(
                                    SecureStorageKeys.ACTIVE_CHARGIE_ID,
                                    desiredChargieId.chargieId
                                );
                                store.dispatch(
                                    setActiveChargieId(
                                        desiredChargieId.chargieId
                                    )
                                );
                                saveData(
                                    SecureStorageKeys.ACTIVE_CHARGIE_ID,
                                    desiredChargieId.chargieId
                                );

                                store.dispatch(setDesiredChargieId(null));
                                store.dispatch(setPaymentMethod(null));
                                navigate('/session', { replace: true });
                            }
                        }
                    } else {
                        if (selectedPaymentMethod === Guest.PLATFORM_PAY) {
                            setCCError(undefined);
                            setPlatformError(
                                'Payment failed. Please try again.'
                            );
                        } else if (
                            selectedPaymentMethod === Guest.CREDIT_CARD
                        ) {
                            setCCError('Payment failed. Please try again.');
                            setPlatformError(undefined);
                        }
                    }
                } catch (e) {
                    const { graphQLErrors } = e as any;
                    dispatch(setDesiredChargieId(null));
                    if (graphQLErrors && graphQLErrors.length > 0) {
                        if (graphQLErrors[0].errorType === 'NotSubscribed')
                            navigate('/subscribe');
                        else dispatch(setErrorType(graphQLErrors[0].errorType));
                    }
                    navigate('/', { replace: true });
                }
            }
        }
    };

    const onClose = () => {
        navigate('/', { replace: true });
    };

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        const clickedElement = event.target as HTMLElement;
        if (
            clickedElement.className !== 'bubble' &&
            clickedElement.className !== 'bubble-click'
        ) {
            setShowBubble(false);
        }
    };

    return (
        <motion.div
            className="container--navless-has-header"
            initial={
                location.state && location.state.slide === true
                    ? {
                          marginLeft: '100%',
                          width: '100%',
                          transformOrigin: 'left',
                      }
                    : {}
            }
            animate={{ marginLeft: 0 }}
        >
            <div className="inset__header" />
            <div className="content" onClick={handleClick}>
                <div className="payment__container">
                    <div className="content__close">
                        <div onClick={onClose}>
                            <CloseIcon fill={colors.inactiveWhite} />
                        </div>
                    </div>
                    {step === 0 && (
                        <div className="payment__content">
                            <CreditCard />
                            <div className="payment__no-payment-details">
                                No payment on file. Add payment details.
                            </div>
                        </div>
                    )}
                    {step === 1 && (
                        <div className="payment__content--right">
                            <div className="payment__title-preauth">
                                Payment
                            </div>
                            <div
                                className="purchase-auth-message"
                                id="payment_auth"
                            >
                                <div id="payment_auth_message">
                                    A temporary hold of $60 or $85 will be used
                                    to validate your payment card. The hold will
                                    be removed once your session is complete.
                                </div>
                            </div>
                            <RadioButtons
                                options={paymentOptions}
                                selectedValue={
                                    paymentMethod ? selectedPaymentMethod : ''
                                }
                                onChange={handlePaymentChange}
                            />

                            <div className="purchase-summary">
                                <p className="purchase-summary-title">
                                    PURCHASE SUMMARY
                                </p>
                                <div className="purchase-station">
                                    Station {desiredChargieId?.chargieId}
                                </div>
                                <div className="purchase-summary-rates">
                                    <div>Current rate</div>
                                    <div>{currentPrice}</div>
                                </div>
                                {!autoReload &&
                                    !currentPrice.includes('0.00 per') && (
                                        <div className="purchase-summary-rates">
                                            <div>Minimum purchase $0.50</div>
                                        </div>
                                    )}
                                {!autoReload && (
                                    <div
                                        className="bubble-click"
                                        onClick={() => {
                                            setShowBubble(true);
                                        }}
                                    >
                                        Processing fee
                                        <div className="bubble-wrapper">
                                            <div>
                                                <Tooltip
                                                    className="bubble-icon"
                                                    fill={colors.activeWhite}
                                                    stroke={colors.activeWhite}
                                                />
                                            </div>
                                            {showBubble && (
                                                <div className="bubble bubble-center">
                                                    Processing fees are 35¢ +
                                                    3.5% of the transaction
                                                    total
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    <div
                        className="payment__button-container"
                        style={{ marginBottom: 60 }}
                    >
                        <PrimaryButton
                            title={buttonText}
                            onPress={nextStep}
                            disabled={
                                addPaymentLoading ||
                                remoteStartLoading ||
                                remoteStartData?.data?.remoteStart
                            }
                            loading={
                                addPaymentLoading ||
                                remoteStartLoading ||
                                remoteStartData?.data?.remoteStart
                            }
                        />
                    </div>
                </div>
            </div>
            {modalBackground && (
                <div className="modal">
                    <div
                        className="modal__background"
                        onClick={hidePaymentModal}
                    />
                </div>
            )}
        </motion.div>
    );
};

export default App;
