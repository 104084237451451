import { useLazyQuery, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectDesiredChargieId, setErrorType } from '../../appSlice';
import { extractStationDetails, StartData } from '../../models/Station';
import { ChargeStatus, RemoteErrorTypes } from '../../utils/enums';
import { getTouParsed } from '../../utils/tou';
import { numberToDollarFormat } from '../../utils/utility';
import PrimaryButton from '../PrimaryButton';
import PricingBreakdown from './PricingBreakdown';
import PricingTitle from './PricingTitle';

const getStationPricing = loader('../../gql/getStationPricing.graphql');

const GuestPricing: React.FC = () => {
    const dispatch = useAppDispatch();
    const desiredChargieId = useAppSelector(selectDesiredChargieId);
    const [stationData, setStationData] = useState<StartData | null>(null);
    const navigate = useNavigate();
    const [usable, setUsable] = useState(true);
    const [status, setStatus] = useState<string | null>(null);
    const [buttonText, setButtonText] = useState('Try another station');
    const [currentPrice, setCurrentPrice] = useState<string | null>(null);
    const [pricingPolicy, setPricingPolicy] = useState('kWh');
    const [subscriptionRequired, setSubscriptionRequired] = useState(false);
    const [
        getStationPricingDetails,
        { error: getStationPricingError, data: getStationPricingdata },
    ] = useLazyQuery(getStationPricing, {
        fetchPolicy: 'no-cache',
    });

    useEffect(() => {
        if (desiredChargieId) {
            getStationPricingDetails({
                variables: {
                    qrCode: desiredChargieId.chargieId,
                },
            });
        }
    }, [desiredChargieId]);

    useEffect(() => {
        if (desiredChargieId && getStationPricingdata) {
            const startData = extractStationDetails(getStationPricingdata);
            setStationData(startData);
            const pricing =
                startData.station.pricing ||
                startData.station.inheritedPricingFrom;
            const ocppStatus = startData.station.ocppStatus.toUpperCase();
            setSubscriptionRequired(startData.station.isSubscription);
            if (pricing) {
                setCurrentPrice(numberToDollarFormat(pricing.rate || 0));
                if (pricing.policy.toLowerCase() === 'tou') {
                    if (pricing.jsonData) {
                        const touPrices = getTouParsed(
                            startData.todayTouSeason,
                            pricing.jsonData
                        );
                        const currentPrice = touPrices.filter(
                            (item) => item.active
                        )[0];
                        if (currentPrice) setCurrentPrice(currentPrice.price);
                    }
                } else if (pricing.policy === 'HOUR') setPricingPolicy('Hour');
            }
            if (
                ocppStatus === ChargeStatus.UNAVAILABLE ||
                ocppStatus === ChargeStatus.FAULTED
            ) {
                setUsable(false);
                setStatus('UNAVAILABLE');
            } else if (
                ocppStatus !== ChargeStatus.AVAILABLE &&
                ocppStatus !== ChargeStatus.PREPARING
            ) {
                setUsable(false);
                setStatus('IN-USE');
            } else if (
                startData.station.isExclusive ||
                startData.station.property.exclusiveDomain1 ||
                startData.station.property.exclusiveDomain2
            ) {
                setStatus('ASSIGNED');
                setUsable(false);
            } else {
                setButtonText('Continue');
                setUsable(true);
            }
            if (startData.station.lastCommunication) {
                const now = new Date();
                const lastMessage = new Date(
                    startData.station.lastCommunication
                );
                const difference = Math.abs(
                    now.getTime() - lastMessage.getTime()
                );
                // checks if status is available & last pint time was less than 3 minutes
                if (difference > 180000) {
                    setStatus('UNAVAILABLE');
                    setUsable(false);
                    return;
                }
            }
        } else if (getStationPricingError) {
            if (
                getStationPricingError.message.includes(
                    'No station found for qrCode'
                )
            )
                dispatch(setErrorType(RemoteErrorTypes.INVALID_STATION_CODE));
            else dispatch(setErrorType(RemoteErrorTypes.UNEXPECTED));
            navigate('/', { replace: true });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getStationPricingError, getStationPricingdata]);

    const handleSubmit = async () => {
        if (!usable) return;
        if (subscriptionRequired) {
            navigate('/subscribe', { state: { slide: true } });
        } else navigate('/guest/checkout', { state: { slide: true } });
    };

    return (
        <div className="guest-pricing-content">
            <div className="pricing-details-main-content">
                {!usable && (
                    <div className="modal__header--row">
                        <div
                            className="modal__unavailable--header"
                            id="guest_pricing_status_header"
                        >
                            {status}
                        </div>
                    </div>
                )}
                <PricingTitle station={stationData?.station} />
                {currentPrice !== null && (
                    <div>
                        <p
                            className="pricing__price-details-title"
                            id="guest_pricing_details_title"
                        >
                            CURRENT PRICE
                        </p>
                        <p
                            className="pricing__price-title"
                            id="guest_pricing_title"
                        >
                            {currentPrice}/{pricingPolicy}
                        </p>
                    </div>
                )}
                {stationData?.station && (
                    <PricingBreakdown
                        station={stationData.station}
                        todayTouSeason={stationData.todayTouSeason}
                    />
                )}
            </div>
            <div className="modal__button" id="guest_pricing_button_container">
                <PrimaryButton
                    title={buttonText}
                    onPress={handleSubmit}
                    disabled={!usable}
                    id="guest_pricing_submit_button"
                />
                <button
                    className="payment__back-button hidden"
                    id="guest_pricing_back_button"
                    // onClick={() => goBack()}
                >
                    Back
                </button>
            </div>
        </div>
    );
};

export default GuestPricing;
